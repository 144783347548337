import { css } from "@emotion/react";

import { below } from "../../../styles/utils/media";

export default css`
  font-family: BankGothic-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 75px;
  line-height: 55px;
  color: #fff;

  ${below.m3} {
    font-size: 64px;
  }

  ${below.m2} {
    font-size: 48px;
  }

  ${below.m1} {
    font-size: 40px;
    line-height: 1.3;
  }

  ${below.m0} {
    font-size: 33px;
  }
`;
