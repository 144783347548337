import styled from '@emotion/styled';
import Title15 from '../UI/Fonts/Title1.5';
import Text from '../UI/Fonts/Text';
import { below } from '../../styles/utils/media';

export const Outer = styled.div`
  position: relative;
  margin-bottom: 200px;
`;

export const Heading = styled.div`
  text-align: center;
  margin-top: -200px;
  margin-bottom: 100px;
  ${Title15}
`;

export const Video = styled.div`
  position: relative;
  margin-bottom: 150px;
  padding-bottom: 60px;

  ${below.m3} {
    margin-bottom: 120px;
  }
  ${below.m2} {
    margin-bottom: 80px;
  }
  ${below.m1} {
    margin-bottom: 60px;
  }
`;

export const VideoOuter = styled.div`
  width: 80%;
  margin: 0 auto;

  ${below.m3} {
    width: 75%;
  }
  ${below.m2} {
    width: 80%;
  }
  ${below.m1} {
    width: 90%;
  }
`;

export const TextCols = styled.div`
  display: flex;
  width: 45%;
  margin: 0 2.5%;
  max-width: 600px;
  justify-content: space-between;
  flex-direction: column;
  justify-content: flex-start;

  ${below.m1} {
    width: 100%;
    align-items: center;
  }
`;

export const TextCol = styled.div`
  width: 90%;
  flex-shrink: 0;
  margin-bottom: 30px;

  ${Text}
  strong {
    font-weight: 500;
  }

  span {
    color: #3BFDF4;
  }

  big {
    font-size: 36px;
  }

  ${below.m1} {
    width: 90%;
  }
`;

export const TextColQuote = styled.div`
  font-style: italic;
  padding-top: 10px;
  color: #3BFDF4;
`;

export const VideoInner = styled.div`
  position: relative;
  padding-bottom: 60%;
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 8%;
  bottom: 8%;
  left: 0;
  right: 3%;
  width: 97%;
  height: 84%;
`;

export const VideoImgBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9;
`;

export const GridBgImgOuter = styled.div`
  margin-right: -100px;
  margin-left: -100px;
`;

export const GridBgImg = styled.img`
  display: block;
  width: calc(100% + 200px);
`;

export const ColGroup = styled.div`
  display: flex;
  justify-content: center;
  ${below.m1} {
    flex-direction: column;
  }
`;