import React, {useState} from 'react';

import { Link as ScrollLink } from 'react-scroll';

import logoImage from './images/logo.png';
import makersImage from './images/makers.png';
import leftBgImage from "./images/left-bg.png";
import rightBgImage from "./images/right-bg.png";

import {
  Outer,
  Inner,
  BgWrapper,
  LeftBg,
  RightBg,
  HeaderMenu,
  Nav,
  Heading,
  Logo,
  Creator,
  Date,
  On,
  Makers,
  MenuBtn,
} from './styled';

const scrollLinkProps = {
  activeClass: 'active',
  spy: true,
  smooth: true,
  offset: -150,
  duration: 500,
};

const WebsiteHead = () => {
  const [isMenuOpen, handleMenuClick] = useState(false);
  const onMenuBtnClick = () => handleMenuClick(!isMenuOpen);

  return (
    <Outer>

      <HeaderMenu>
        <Nav isMenuOpen={isMenuOpen}>
          <ScrollLink {...{ ...scrollLinkProps, to: 'section-drop' }}>THE DROP</ScrollLink>
          <ScrollLink {...{ ...scrollLinkProps, to: 'section-videos' }}>Character Cuts</ScrollLink>
          <ScrollLink {...{ ...scrollLinkProps, to: 'section-cards' }}>CARDS</ScrollLink>
          <ScrollLink {...{ ...scrollLinkProps, to: 'section-history' }}>HISTORY</ScrollLink>
          <ScrollLink {...{ ...scrollLinkProps, to: 'section-contact' }}>CONTACT</ScrollLink>
        </Nav>
        <MenuBtn isMenuOpen={isMenuOpen} onClick={onMenuBtnClick}>
          <span />
        </MenuBtn>
      </HeaderMenu>

      <Inner>
        <Heading>The First <span>NFT Drop</span> of</Heading>
        <Logo>
          <img src={logoImage} alt="" />
        </Logo>
        <Date>May 13<span>TH</span> 2021</Date>
        <On>ON</On>
        <a href="https://makersplace.com/the7thportal/drops/let-the-game-begin" ><Makers src={makersImage} /></a>
      </Inner>

      <BgWrapper>
        <LeftBg>
          <img src={leftBgImage} alt=""/>
        </LeftBg>
        <RightBg>
          <img src={rightBgImage} alt=""/>
        </RightBg>
      </BgWrapper>
    </Outer>
  );
};

export default WebsiteHead;
