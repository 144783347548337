import React, { useRef, useState } from 'react';
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import CursorText from  '../../../images/cursors/text.png';

import AutosizeInput from 'react-input-autosize';

const AutosizeInputStyledOuter = styled(AutosizeInput)`
    box-sizing: border-box;
    max-width: 100%;
    position: relative;

    &:focus-within:after {
        content: '';
        width: 10px;
        height: 20px;
        background: #00F0FF;
        opacity: 0.22;
        position: absolute;
        right: 28px;
        top: calc(50% - 9px);
        border: 1px solid #59EFEC;
        animation: 0.8s caret-animate infinite;
    }
`;

const AutosizeInputStyleableInner = ({ className, ...props }) => <AutosizeInputStyledOuter {...props} inputClassName={className} />;

const AutosizeInputStyledInner = styled(AutosizeInputStyleableInner)`
    font-family: Ubuntu Regular, sans-serif;
    font-size: 24px;
    line-height: 30px;
    color: transparent;
    background: none;
    height: 100%;
    outline: none;
    padding: 18px 40px; 
    border: none;
    max-width: calc(100% - 80px);
    text-shadow: 0 0 0 #fff;
    

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active
    {
    -webkit-box-shadow: 0 0 0 1000px #010014 inset !important;
    -webkit-text-fill-color: #fff;
    }
`;

const InputWrapper = styled.div`
  height: max(min(6.54vmin, 33px), 67px);
  width: 100%;
  border: 1px solid #fff;
  cursor: url(${CursorText}), auto;
  margin-bottom: 20px;
`;


const focusedCss = css`
  border: 1px solid #3BFDF4;
`;

const errorCss = css`
  border: 1px solid #E43D99;
`;

const TextField = ({ value, onChange, className, ...rest }) => {
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef();
  const mainRef = useRef();

  const handleClick = (event) => {
      if (mainRef.current && event.target === mainRef.current) {
        event.preventDefault();
        event.stopPropagation();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
  };

  const handleChange = (e) => onChange(e.target.value);

  return (
    <InputWrapper css={[
        focused && focusedCss,
        error && errorCss,
    ]} onClick={handleClick} ref={mainRef}>
      <AutosizeInputStyledInner
        {...rest}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onInvalid={() => setError(true)}
        // onInput={() => setError(false)}
        value={value}
        onChange={handleChange}
        inputRef={(el) => { inputRef.current = el; }}
      />
    </InputWrapper>
  )
};

export default TextField;
