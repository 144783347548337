import styled from '@emotion/styled';
import DoubleButton from '../UI/DoubleButton';
import Title15 from '../UI/Fonts/Title1.5';
import PointerCursor from '../../images/cursors/pointer.png';

export const HistoricPressWrapper = styled.div`
  position: relative;
  max-width: 1364px;
  margin: 70px auto;
`;

export const Heading = styled.div`
  text-align: center;
  ${Title15}
`;

export const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 172px -10% 146px;
`;

export const SliderCard = styled.div`
  position: relative;
  width: 357px;
  transition: transform 0.3s;
  cursor: url(${PointerCursor}), auto;
  
  &:hover {
    transform: scale(1.2);
    
    &:after {
      opacity: 0;
    }
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: rgba(0, 0, 0, 0.31);
    transition: opacity 0.3s;
  }
  
  img {
    width: 100%;
  }
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ReadMore = styled(DoubleButton)`
  position: relative;
  display: block;
  width: 100%!important;
  max-width: 340px;
`;
