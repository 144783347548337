import React from "react";
import styled from "@emotion/styled";
import MediaQuery from "react-responsive";

const FullSizeLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Section = ({ background, children, heights, resultHeight = '100vh', id = '' }) => {
  return (
    <>
      {heights && heights.length && heights.map(({ minWidth, maxWidth, resultHeight }) => (
        <MediaQuery minDeviceWidth={minWidth} maxDeviceWidth={maxWidth}>
          <Wrapper style={{ height: resultHeight }} {...(id ? { id } : {})}>
            <FullSizeLayer>{background}</FullSizeLayer>
            <FullSizeLayer>{children}</FullSizeLayer>
          </Wrapper>
        </MediaQuery>
      ))}
      {(!heights || !heights.length)  && (
        <>
          <Wrapper style={{ height: resultHeight }} {...(id ? { id } : {})}>
            <FullSizeLayer>{background}</FullSizeLayer>
            {children}
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Section;
