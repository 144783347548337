import React from "react";
import PropTypes from "prop-types";

import {
  CardWrapper,
  CardInfo,
  CardInfoWrapper,
  CardVideo,
  CardLabel,
  CardVideoBg,
  CardTitle,
  CardInfoRows,
  CardInfoRow,
  CardInfoTitle,
  CardInfoDescription,
  VideoPlayer,
} from "./styled";

import cardBg from "./images/cardBg.svg";


const HeroVideoCard = (props) => {
  const {
    cardPosition,
    labelText,
    title,
    name,
    countryOfOrigin,
    sHeroForm,
    sPower,
    voiceActor,
    videoUrl,
  } = props;

  return (
    <CardWrapper cardPosition={cardPosition}>
      <CardInfo>
        <CardInfoWrapper>
          <CardTitle>{title}</CardTitle>
          <CardInfoRows>
            <CardInfoRow>
              <CardInfoTitle>Name:</CardInfoTitle>
              <CardInfoDescription>{name}</CardInfoDescription>
            </CardInfoRow>
            <CardInfoRow>
              <CardInfoTitle>Country of Origin:</CardInfoTitle>
              <CardInfoDescription>{countryOfOrigin}</CardInfoDescription>
            </CardInfoRow>
            <CardInfoRow>
              <CardInfoTitle>Superhero form:</CardInfoTitle>
              <CardInfoDescription>{sHeroForm}</CardInfoDescription>
            </CardInfoRow>
            <CardInfoRow>
              <CardInfoTitle>Superpower:</CardInfoTitle>
              <CardInfoDescription>{sPower}</CardInfoDescription>
            </CardInfoRow>
            <CardInfoRow>
              <CardInfoTitle>Voice actor:</CardInfoTitle>
              <CardInfoDescription>{voiceActor}</CardInfoDescription>
            </CardInfoRow>
          </CardInfoRows>
        </CardInfoWrapper>
        <CardVideo>
          <CardVideoBg src={cardBg} alt="cardBg" />
          <VideoPlayer
            videoUrl={videoUrl}
            countryOfOrigin=""
            labelText=""
            name=""
            sHeroForm=""
            sPower=""
            title=""
            voiceActor=""
          />
          <CardLabel position={cardPosition} text={labelText} />
        </CardVideo>
      </CardInfo>
    </CardWrapper>
  );
};

HeroVideoCard.propTypes = {
  labelText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  countryOfOrigin: PropTypes.string.isRequired,
  sHeroForm: PropTypes.string.isRequired,
  sPower: PropTypes.string.isRequired,
  voiceActor: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  cardPosition: PropTypes.oneOf(["left", "right"]),
};

HeroVideoCard.defaultProps = {
  cardPosition: "left",
};

export default HeroVideoCard;
