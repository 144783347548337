import React from 'react';
import styled from "@emotion/styled";

const TxtArea = styled.textarea`
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
    font-family: Ubuntu Regular, sans-serif;
    font-size: 24px;
    line-height: 30px;
    background: none;
    height: max(min(23.4%,137px),242px);
    outline: none;
    padding: 18px 40px; 
    border: 1px solid #fff;
    width: 100%;
    color: transparent;
    text-shadow: 0 0 0 #fff;

    &:focus {
      border: 1px solid #3BFDF4;
    }
`;

const TextArea = ({ value, onChange, className, ...rest }) => {
  const handleChange = (e) => onChange(e.target.value);

  return (
    <TxtArea
      {...rest}
      value={value}
      onChange={handleChange}
    />
  )
};

export default TextArea;
