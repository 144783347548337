import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import press_1 from './images/press_1.jpg';
import press_1_orig from './images/press_1_orig.jpg';
import press_2 from './images/press_2.jpg';
import press_2_orig from './images/press_2_orig.png';
import press_3 from './images/press_3.jpg';
import press_3_orig from './images/press_3_orig.jpg';
import press_4_orig from './images/press_4_orig.jpg';

import {
  HistoricPressWrapper,
  Heading,
  SliderWrapper,
  SliderCard,
} from './styled';

const HistoricPress = () => {
  const [openedImage, setOpenedImage] = useState('');

  return (
    <HistoricPressWrapper>
      <Heading>HISTORIC PRESS</Heading>
      <SliderWrapper>
        <SliderCard onClick={() => setOpenedImage(press_1_orig)}>
          <img src={press_1} alt="sliderCard" />
        </SliderCard>
        <SliderCard onClick={() => setOpenedImage(press_4_orig)}>
          <img src={press_4_orig} alt="sliderCard" />
        </SliderCard>
        <SliderCard onClick={() => setOpenedImage(press_2_orig)}>
          <img src={press_2} alt="sliderCard" />
        </SliderCard>
        <SliderCard onClick={() => setOpenedImage(press_3_orig)}>
          <img src={press_3} alt="sliderCard" />
        </SliderCard>
      </SliderWrapper>
      {openedImage && (
        <Lightbox
          mainSrc={openedImage}
          onCloseRequest={() => setOpenedImage('')}
        />
      )}
    </HistoricPressWrapper>
  );
};

export default HistoricPress;
