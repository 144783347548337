import React, {
  useCallback,
  useState
} from "react";
import styled from "@emotion/styled";
import {
  API_GATEWAY_ENV,
  API_GATEWAY_URL
} from "gatsby-env-variables";

import Title1_5 from "../UI/Fonts/Title1.5"
import ImgCF from "./CF_Cover.png"
import ImgCFCut from "./CF_Cover_Cut.png"
import DoubleButton from "../UI/DoubleButton";
import CardBody from "../UI/Fonts/CardBody";
import TextField from "../UI/TextField";
import TextArea from "../UI/TextArea";
import { below } from "../../styles/utils/media";

const BackWr = styled.div`
  background: rgba(1, 0, 22, 0.8);
  box-shadow: 0 0 50px #3bfdee;
  border-radius: 20px;
  height: max(80vh, 900px);
  display: flex;
	justify-content: flex-start;
  align-items: center;
  ${below.m0} {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  ${below.m1} {
    height: unset;
  }
	
  
`;

const PictureWr = styled.div `
  pointer-events: none;
  position:absolute;
  background: url(${ImgCF});
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  z-index: 999;
  overflow-x: visible;
  border: none;
  width:100vw;
  height: 90%;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  
  ${below.m2} {
    display: none;
    margin-left: calc(50% - 23vw); 
  }
  ${below.m3} {
   margin-left: calc(50% - 23vw); 
  }
`;
const PictureWrMob = styled.div `
  display: none;
  pointer-events: none;
  position:absolute;
  background: url(${ImgCFCut});
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  z-index: 999;
  overflow-x: visible;
  border: none;
  width:100vw;
  height: calc(1.033*100vw);
  top: 100vh;
  ${below.m1} {
    display: block;
    top: 800px;
  }
`;

const Form = styled.form `
  width: 90vw;
  max-width: 788px;
  height: 100%;
  display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;  
  padding: 3vmax;
  ${below.m2} {
    text-align: center;
    align-items: center; 
    max-width: 100%;
  }
`;

const TitleWr = styled.div `
height: auto
`;
const TextWr = styled.div `
height: auto;
`;

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const handleSubmit = useCallback(async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      console.log(`Start handleSubmit, isLoading:${isLoading}, isLoaded:${isLoading}`);
      await fetch(`${API_GATEWAY_URL}/${API_GATEWAY_ENV}/contact-form`, {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          message
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      setLoaded(true);
      setLoading(false);
      console.log(`End handleSubmit, isLoading:${isLoading}, isLoaded:${isLoading}`);
    } finally {
      setLoading(false);
    }
  }, [name, email, message]);
  console.log(`return handleSubmit, isLoading:${isLoading}, isLoaded:${isLoading}`);
  return (
  <BackWr>
    <PictureWr/>
    {
      !isLoaded && (
        <Form onSubmit = {handleSubmit} >
          <TitleWr css = {Title1_5} > CONTACT US </TitleWr>
          <TextWr css = {CardBody}> Please contact us for more information regarding <br/> the May 13, 2021 drop. </TextWr>
          <TextField name="name" type="text" required value={name} onChange={setName} />
          <TextField name="email" type="email" required value={email} onChange={setEmail} />
          <TextArea name="message" required cols="30" rows="10" value={message} onChange={setMessage} />
          <DoubleButton width="350px" disabled={isLoading}>
            {!isLoading ? 'SEND' : '...'}
          </DoubleButton>
        </Form>
      )
    }
    {
      isLoaded && (
        <Form onSubmit = {(event) => {
          event.preventDefault();
          setLoaded(false);
          setName('');
          setEmail('');
          setMessage('');
        }} >
          <TitleWr css = {Title1_5} > THANK YOU! </TitleWr>
          <TextWr css = {CardBody}> Thank you for contacting us.<br/>We will get back to you as soon as possible.</TextWr>
          <DoubleButton width="350px">SEND MORE</DoubleButton>
        </Form>
      )
    }
    <PictureWrMob/>
  </BackWr>
);
};

export default ContactForm;
