import React from "react";
import PropTypes from "prop-types";

import {LabelWrapper} from "./styled"

const Label = ({ text, position, className }) => (
  <a href="https://makersplace.com/the7thportal/drops/let-the-game-begin" target="_blank">
    <LabelWrapper className={className} position={position}>{text}</LabelWrapper>
  </a>
)

Label.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.oneOf(["left", "right"])
}

Label.defaultProps = {
  position: "left"
}

export default Label
