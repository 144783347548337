import { css } from '@emotion/react';
import {below} from '../../../styles/utils/media';

export default css`
    font-family: Ubuntu Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 35px;
    color: #fff;

    ${below.m2} {
        font-size: 20px;
        line-height: 24px;
    }

    ${below.m1} {
        font-size: 16px;
        line-height: 20px;
    }

    ${below.m0} {
        font-size: 12px;
        line-height: 16px;
    }
`;
