import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {below} from "../../styles/utils/media";

import Header from "../UI/Fonts/Header";
import Title1 from "../UI/Fonts/Title1";
import Subtitle0 from "../UI/Fonts/Subtitle0";
import PointerCursor from '../../images/cursors/pointer.png';

export const Outer = styled.div`
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(179.97deg, #000 .02%, #020018 99.98%);
  padding-top: 152px;
  padding-bottom: 50px;

  ${below.m1} {
    padding-bottom: 0;
    padding-top: 80px;
  }
`;

export const Inner = styled.div`
  position: relative;
  z-index: 1;
`;

export const BgWrapper = styled.div`
  ${below.m1} {
    position: relative;
    left: 50%;
    width: 100vw;
    margin-top: 21px;
    padding-bottom: 122%;
    transform: translate(-50%, 0);
  }

  ${below.m0} {
    padding-bottom: 105%;
  }
`;

const Bg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;

  img {
    height: 100%;
  }

  ${below.m1} {
    width: auto;
    height: auto;
  }
`;

export const LeftBg = styled(Bg)`
  right: 60%;
  text-align: right;

  ${below.m1} {
    right: 19%;
  }
`;

export const RightBg = styled(Bg)`
  left: 60%;

  ${below.m1} {
    left: 19%;
  }
`;

export const Heading = styled.div`
  text-align: center;
  margin-bottom: 13px;
  ${Title1}

  span {
    color: #3bfdf4;
  }

  ${below.m0} {
    max-width: 250px;
    margin: 0 auto 13px;
  }
`;

export const Logo = styled.div`
  width: 720px;
  margin: 50px auto;
  margin-top: 100px;
  animation: 2s logo-animate infinite;

  img {
    display: block;
    width: 100%;
  }

  ${below.m2} {
    width: 480px;
  }

  ${below.m2} {
    width: 340px;
  }

  ${below.m0} {
    width: 303px;
    
    img {
      margin: 0;
    }
  }
`;

export const Creator = styled.div`
  text-align: center;
  color: rgba(255, 255, 255, .5);
  margin-bottom: 58px;
  ${Subtitle0}

  ${below.m2} {
    margin-bottom: 40px;
  }

  ${below.m0} {
    margin-bottom: 30px;
  }
`;

export const Date = styled.div`
  margin: 0 auto 65px;
  width: fit-content;
  position: relative;
  ${Title1}

  span {
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
    line-height: 15px;
    color: #FFF;
    vertical-align: top;
  }

  &:after {
    content: "";
    position: absolute;
    right: -40px;
    top: 50%;
    width: 34px;
    height: 72px;
    transform: translate(0, -50%);
    background: rgba(0, 240, 255, .22);
    border: 1px solid #59EFEC;
    animation: 0.8s caret-animate infinite;

    ${below.m0} {
      right: -20px;
      width: 18px;
      height: 38px;
    }
  }

  ${below.m2} {
    margin: 0 auto 40px;
  }

  ${below.m2} {
    margin: 0 auto 20px;
  }

  ${below.m0} {
    margin: 0 auto 10px;
  }
`;

export const On = styled.div`
  color: rgba(255, 255, 255, .5);
  text-align: center;
  margin-bottom: 5px;
  ${Subtitle0}

  ${below.m0} {
    margin: 0;
  }
`;

export const Makers = styled.img`
  display: block;
  width: 717px;
  margin: 0 auto;

  ${below.m2} {
    width: 600px;
  }

  ${below.m1} {
    width: 340px;
  }
`;

export const HeaderMenu = styled.div`
  display: flex;
  z-index: 9;
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  height: 64px;
  background: linear-gradient(179.97deg, #000000 0.02%, rgba(2, 0, 24, 0.58) 99.98%);
  text-transform: uppercase;

  ${below.m1} {
    top: 0;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    background: #111111;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    margin: 0 15px;
    cursor: url(${PointerCursor}), auto;
    transition: color .3s;
    ${Header}
    
    &:hover,
    &.active {
      color: #3bfdf4;
    }

    ${below.m1} {
      margin: 0 0 30px;
    }

    &:last-child {
      ${below.m1} {
        margin-bottom: 0;
      }
    }
  }

  ${below.m1} {
    position: absolute;
    top: 102%;
    right: -100%;
    flex-flow: column;
    align-items: flex-end;
    width: auto;
    padding: 30px 40px;
    background: #111111;
    transition: 0.3s;
  }

  ${({isMenuOpen}) => isMenuOpen && css`
      ${below.m1} {
        right: 0;
      }
    `}
  }
`;

export const MenuBtn = styled.div`
  position: relative;
  display: none;
  align-items: center;
  width: 36px;
  height: 26px;
  cursor: url(${PointerCursor}), auto;
  z-index: 1;

  ${below.m1} {
    display: flex;
  }

  span,
  span:before,
  span:after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #FFFFFF;
    transition-duration: .25s;
  }
  
  span {
    &:before {
      content: '';
      top: -12px;
    }

    &:after {
      content: '';
      top: 12px;
    }
  }

  ${({isMenuOpen}) => isMenuOpen && css`
      span {
        transform: rotate(45deg);
      }
      span:before {
        top: 0;
        transform: rotate(0);
      }
      span:after {
        top: 0;
        transform: rotate(90deg);
      }
    `}
  }
`;
