import { css } from '@emotion/react';

export default css`
    font-family: Eurostile, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 50px;
    color: #3BFDF4;
`;
