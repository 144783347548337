import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { below } from '../../styles/utils/media';
import Label from '../UI/Label';
import CardTitleFont from '../UI/Fonts/CardTitle';
import Player from '../Player';

export const CardLabel = styled(Label)`
  position: absolute;
  top: 5%;

  ${below.m1} {
    top: -46px;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  max-width: 1364px;
  margin: 100px auto;
  margin-bottom: 50px;

  ${({cardPosition}) => {
    switch (cardPosition) {
      case 'left':
        return css`
          ${CardInfo} {
            margin-left: auto;
            align-items: flex-end;

            ${below.m1} {
              align-items: stretch;
            }
          }
          ${CardVideo} {
            right: 386px;
            left: -130px;

            &:hover {
              transform: scale(1.08) translate(-5%, -46%);

              ${below.m1} {
                transform: scale(1) translate(0, 0);
              }
            }

            ${below.m3} {
              right: 320px;
              left: -80px;
            }

            ${below.m2} {
              right: 260px;
            }

            ${below.m1} {
              right: auto;
              left: auto;
            }
          }
          ${CardLabel} {
            left: -50px;

            ${below.m2} {
              left: -40px;
            }

            ${below.m1} {
              left: auto;
              right: -10px;
            }
          }
        `;
      case 'right':
      default:
        return css`
          ${CardInfo} {
            margin-right: auto;
          }
          ${CardVideo} {
            left: 386px;
            right: -130px;

            ${below.m3} {
              left: 320px;
              right: -80px;
            }

            ${below.m2} {
              left: 260px;
            }

            ${below.m1} {
              left: auto;
              right: auto;
            }

            &:hover {
              transform: scale(1.08) translate(5%, -46%);

              ${below.m1} {
                transform: scale(1) translate(0, 0);
              }
            }
          }
          ${CardVideoBg} {
            transform: matrix(-1, 0, 0, 1, 0, 0);
          }
          ${CardLabel} {
            right: -50px;

            ${below.m2} {
              right: -40px;
            }

            ${below.m1} {
              right: -10px;
            }
          }
        `;
    }
  }}
`;

export const CardVideo = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: 0.3s;
  will-change: transform;
  z-index: 2;

  ${below.m1} {
    position: relative;
    top: auto;
    margin: 0 -8px;
    transform: none;
  }
`;

export const CardVideoBg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  will-change: transform;

  ${below.m1} {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;

export const VideoPlayer = styled(Player)`
  top: 50px;
  height: calc(100% - 100px);
`;

export const CardInfo = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 90%;
  height: 769px;
  border-radius: 30px;
  border: 2px solid rgba(59, 253, 244, 0.74);
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  font-family: Eurostile, sans-serif;
  
  ${below.m3} {
    height: auto;
  }
  
  ${below.m1} {
    display: flex;
    flex-flow: column-reverse;
    align-items: stretch;
    width: 100%;
    padding-top: 56px;
  }
`;

export const CardInfoWrapper = styled.div`
  position: relative;
  min-width: 420px;
  max-width: 420px;
  padding: 70px;

  ${below.m3} {
    min-width: 330px;
    max-width: 330px;
    padding: 50px;
  }

  ${below.m2} {
    min-width: 260px;
    max-width: 260px;
    padding: 50px 30px;
  }

  ${below.m1} {
    min-width: auto;
    max-width: 100%;
    padding: 30px 37px 40px;
  }
`;

export const CardTitle = styled.h3`
  margin-bottom: 50px;
  ${CardTitleFont}

  ${below.m3} {
    margin-bottom: 40px;
    font-size: 44px;
  }

  ${below.m2} {
    font-size: 34px;
  }

  ${below.m1} {
    font-size: 24px;
    margin-bottom: 27px;
  }
`;

export const CardInfoRows = styled.div`
  position: relative;

  ${below.m1} {
    column-count: 2;
    column-gap: 30px;
  }
`;

export const CardInfoRow = styled.div`
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1.1;
  color: #3BFDF4;

  ${below.m3} {
    margin-bottom: 22px;
    font-size: 26px;
  }

  ${below.m2} {
    font-size: 22px;
  }

  ${below.m1} {
    font-size: 18px;
    margin-bottom: 18px;
  }
`;

export const CardInfoTitle = styled.p`
  margin: 0;
  line-height: 1.2;
  color: rgba(59, 253, 244, 0.5);
  white-space: nowrap;
`;

export const CardInfoDescription = styled.p`
  margin: 0;
`;
