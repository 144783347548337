import {css} from '@emotion/react';
import styled from '@emotion/styled';
import LabelBody from '../UI/Fonts/LabelBody';
import { below } from '../../styles/utils/media';
import PointerCursor from '../../images/cursors/pointer.png';

export const SmallHeroCardsWrapper = styled.div`
  position: relative;
  max-width: 1364px;
  margin: 0 auto;
  padding-bottom: 57px;

  ${({characterGroup}) => {
    switch (characterGroup) {
      case 'Villains':
        return css`
          ${Title} {
            b {
              color: #7F52FD;
            }
          }
          ${Label} {
            background: #9E7AFF;
          }
        `;
      case 'Heroes':
      default:
        return css`
          ${Title} {
            b {
              color: #3BFDF4;
              opacity: 0.74;
            }
          }
          ${Label} {
            background: #3BFDF4;
          }
        `;
    }
  }}
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 109px;

  ${below.m1} {
    margin-bottom: 60px;
  }
`;

export const Title = styled.div`
  font-family: BankGothic-Medium, sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 1.2;

  ${below.m3} {
    font-size: 40px;
  }

  ${below.m2} {
    font-size: 34px;
  }

  ${below.m1} {
    font-size: 22px;
  }

  ${below.m0} {
    font-size: 18px;
  }
`;

export const Label = styled.div`
  position: relative;
  width: 307px;
  height: 78px;
  margin-left: 20px;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${LabelBody}

  ${below.m3} {
    width: 280px;
    height: 66px;
    font-size: 26px;
    line-height: 68px;
  }

  ${below.m2} {
    width: 240px;
    height: 56px;
    font-size: 20px;
    line-height: 58px;
  }

  ${below.m1} {
    width: 200px;
    height: 44px;
    font-size: 16px;
    line-height: 46px;
  }

  ${below.m0} {
    width: 155px;
    height: 35px;
    font-size: 14px;
    line-height: 36px;
  }
`;

export const CharacterCardsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;

  ${below.m3} {
    justify-content: space-between;
  }

  ${below.m1} {
    flex-flow: column;
    justify-content: center;
  }
`;

export const CharacterCard = styled.div`
  position: relative;
  width: 30%;
  cursor: url(${PointerCursor}), auto;

  ${below.m3} {
    width: 31%;
  }

  ${below.m1} {
    width: 100%;
    max-width: 346px;
    margin-bottom: 75px;
  }
  
  &:last-child {
    margin-right: 0;

    ${below.m1} {
      margin-bottom: 0;
    }
  }
  
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid #3BFDF4;
    border-radius: 20px 0 20px 20px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 1;
    transition: 0.3s;
    will-change: transform;
  }
  
  &:hover {
    &:before,
    &:after {
      width: 106%;
      height: 102%;
      opacity: 1;
    }
    &:before {
      transform: translate(-49%, -48%);
    }
    &:after {
      transform: translate(-52%, -52%);
    }
  }
`;

export const CharacterCardPicture = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
`;
