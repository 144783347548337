import styled from "@emotion/styled";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link as ScrollLink } from 'react-scroll';

import DoubleButton from "../UI/DoubleButton";
import Subtitle0 from "../UI/Fonts/Subtitle0";
import Title2 from "../UI/Fonts/Title2";
import Body2 from "../UI/Fonts/Body2";
import Label from "../UI/Label";
import Body2Mint from "../UI/Fonts/Body2Mint";
import Player from "../Player/Player";
import { below, belowQuery } from "../../styles/utils/media";


const CardLabel = styled(Label)`
  position: absolute;
  top: 30px;
  right: -50px;

  ${below.m1} {
    right: -30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 50px #3BFDEE;
  padding: 30px;
  position: relative;
  background: #000;
  max-width: 1200px;
  margin: 100px auto;
  margin-top: calc(-100px - 10vw);
  border-radius: 20px;

  ${below.m2} {
    padding: 30px;
  }
  ${below.m1} {
    padding: 24px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: calc(100% - 200px);

  ${below.m2} {
    margin-top: 70px;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColumnWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 30px;
  ${below.m2} {
    width: 100%;
    padding-top: 0;
  }
`;

const ColumnWrapperLeft = styled(ColumnWrapperRight)`
  ${below.m2} {
    padding-bottom: 0;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  max-height: 684px;
  height: 48vw;
`;

const Button = styled(DoubleButton)`
  max-width: 300px;
  width: 100%;
`;

const ContentHeading = styled.div`
  margin-bottom: 20px;
  ${Subtitle0};
  color: #3BFDF4;
`;

const ColumnWrapper = styled.div`
  display: flex;

  ${below.m2} {
    flex-direction: column;
  }
`;

const ListWrapper = styled.ul`
  ${below.m2} {
    margin-bottom: 0;
  }
`;

const Anchor = styled.a`
  max-width: 300px;
  width: 100%;
`;

const EpisodeCard = () => {
  const isMobileOrTablet = useMediaQuery({ query: belowQuery.m2 });
  const isMobile = useMediaQuery({ query: belowQuery.m1 });

  console.log(isMobileOrTablet, isMobile);
  return (
    <Wrapper>
      <TitleWrapper>
        <div css={Title2}>Webisode 1 - Let The Game Begin</div>
        <div css={Subtitle0}>The 7th Portal</div>
        <CardLabel position="right" text="Edition of 1" />
      </TitleWrapper>
      <PlayerWrapper>
        <Player autoplay scale100 videoUrl="https://player.vimeo.com/video/546139608?title=0&byline=0&portrait=0&sidedock=0&controls=0" />
      </PlayerWrapper>
      <ContentWrapper>
        <ContentHeading>Key Facts</ContentHeading>
        <ColumnWrapper>
          <ColumnWrapperLeft>
            <ListWrapper css={Body2}>
              <li>The first webisode of the historic 7th Portal franchise.</li>
              <li>The first public use of the term “webisode” to describe an animated series on the internet.</li>
              <li>The first universe of superheroes created exclusively for the internet.</li>
              <li>The first set of superheroes created by Stan Lee since he created the X-Men over three decades earlier.</li>
            </ListWrapper>
          </ColumnWrapperLeft>
          <ColumnWrapperRight>
            <ul css={Body2}>
              <li>Debut broke Macromedia’s Shockwave servers with millions of viewers from around the world.</li>
            </ul>
            <p css={Body2Mint}>
              BONUS: The collector of “The 7th Portal - Webisode 1- Let The Game Begin” will
              automatically receive the first edition of each of the editioned pieces!
            </p>
          </ColumnWrapperRight>
        </ColumnWrapper>
        <Anchor href="https://makersplace.com/the7thportal/drops/let-the-game-begin"><Button>GET MORE INFO</Button></Anchor>
      </ContentWrapper>
    </Wrapper>
  );
};

export default EpisodeCard;
