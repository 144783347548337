import { css } from '@emotion/react';

import {below} from '../../../styles/utils/media';

export default css`
  font-family: BankGothic-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 55px;
  color: rgba(255, 255, 255, 0.5);

  ${below.m3} {
    font-size: 36px;
  }

  ${below.m2} {
    font-size: 28px;
  }

  ${below.m1} {
    font-size: 24px;
    line-height: 40px;
  }

  ${below.m0} {
    font-size: 22px;
    line-height: 1;
  }
`;
