import React from 'react';
import styled from '@emotion/styled';
import Title1 from '../UI/Fonts/Title1';
import Title2 from '../UI/Fonts/Title2';
import Subtitle1 from '../UI/Fonts/Subtitle1';
import lineCircleImage from './images/line-bg.svg';
import { below } from "../../styles/utils/media";
import { css } from '@emotion/react';


const Outer = styled.div`
  margin: 0 auto;
  margin-bottom: 90px;
  position: relative;
  padding-top: 250px;
  max-width: 1364px;

  ${below.m1} {
    padding-top: 150px;
  }
`;

const NoExtraElCss = css`
  padding-top: 50px;
`;

const Inner = styled.div`
  max-width: 900px;
  margin-left: 0;
  margin-right: auto;
`;

const Heading = styled.div`
  ${Title1}
`;

const SubHeading = styled.div`
  ${Title2};
  color: #9D9D9D;
  margin-bottom: 71px;
`;

const Text = styled.div`
  ${Subtitle1};
  line-height: 50px;
`;

const LineCircleBg = styled.img`
  display: block;
  position: absolute;
  right: -100px;
  top: 100px;
`;

const SmallHeroCardsHead = ({ text, title, subtitle, extraEl }) => (
  <Outer css={!extraEl && NoExtraElCss}>
    {extraEl && <LineCircleBg src={lineCircleImage} />}
    <Inner>
      <Heading>{title}</Heading>
      <SubHeading>{subtitle}</SubHeading>
      <Text>{text}</Text>
    </Inner>
  </Outer>
);

export default SmallHeroCardsHead;
