import { css } from "@emotion/react";

import {below} from "../../../styles/utils/media";

export default css`
  font-family: BankGothic-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 11px;
  color: #fff;

  ${below.m1} {
    font-size: 22px;
  }
`;
