import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

import {
  SmallHeroCardsWrapper,
  Header,
  Title,
  Label,
  CharacterCardsWrapper,
  CharacterCard,
  CharacterCardPicture,
} from "./styled";
import styled from '@emotion/styled';

const Anchor = styled.a`
  text-decoration: none;
  color: #000;
`;

const SmallHeroCards = ({characterGroup, characters}) => {
  const [openedImage, setOpenedImage] = useState('');

  return (
    <SmallHeroCardsWrapper characterGroup={characterGroup}>
      <Header>
        <Title>Character Cards - <b>{characterGroup}</b></Title>
        <Anchor href="https://makersplace.com/the7thportal/drops/let-the-game-begin" target="_blank">
          <Label>Limited edition</Label>
        </Anchor>
      </Header>
      <CharacterCardsWrapper>
        {characters.map((character, key) => (
          <CharacterCard
            key={key}
            onClick={() => setOpenedImage(character.orig)}
          >
            <CharacterCardPicture
              src={character.img}
              alt="character"
            />
          </CharacterCard>
        ))}
      </CharacterCardsWrapper>
      {openedImage && (
        <Lightbox
          mainSrc={openedImage}
          onCloseRequest={() => setOpenedImage('')}
        />
      )}
    </SmallHeroCardsWrapper>
  );
};

SmallHeroCards.propTypes = {
  characterGroup: PropTypes.oneOf(["Heroes", "Villains"]),
  characters: PropTypes.array,
};

SmallHeroCards.defaultProps = {
  characterGroup: 'Heroes',
  characters: [],
};

export default SmallHeroCards;
