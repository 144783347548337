import { css } from '@emotion/react';
import {below} from '../../../styles/utils/media';

export default css`
    font-family: BankGothic-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 47px;
    line-height: 55px;
    color: #fff;

    ${below.m3} {
        font-size: 40px;
        line-height: 47px;
    }

    ${below.m2} {
        font-size: 33px;
        line-height: 40px;
    }

    ${below.m1} {
        font-size: 28px;
        line-height: 33px;
    }

    ${below.m0} {
        font-size: 24px;
        line-height: 28px;
    }
`;
