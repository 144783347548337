import Streak from "../../images/characters/Streak.jpg";
import StreakOrig from "../../images/characters/StreakOrig.jpg";
import Oxblood from "../../images/characters/Oxblood.jpg";
import OxbloodOrig from "../../images/characters/OxbloodOrig.jpg";
import Imitatia from "../../images/characters/Imitatia.jpg";
import ImitatiaOrig from "../../images/characters/ImitatiaOrig.jpg";
import Mongorr from "../../images/characters/Mongorr.jpg";
import MongorrOrig from "../../images/characters/MongorrOrig.jpg";
import Slyme from "../../images/characters/Slyme.jpg";
import SlymeOrig from "../../images/characters/SlymeOrig.jpg";
import Vedetta from "../../images/characters/Vedetta.jpg";
import VedettaOrig from "../../images/characters/VedettaOrig.jpg";

export const heroes = [
  { img: Streak, orig: StreakOrig },
  { img: Oxblood, orig: OxbloodOrig },
  { img: Imitatia, orig: ImitatiaOrig },
];

export const villains = [
  { img: Mongorr, orig: MongorrOrig },
  { img: Slyme, orig: SlymeOrig },
  { img: Vedetta, orig: VedettaOrig },
];
