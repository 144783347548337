import React from 'react';
import imgAll from './images/all.svg';
import Player from '../Player';
import whiteGridImage from '../../images/background/white-grid.svg';
import {
  Outer,
  Heading,
  Video,
  VideoOuter,
  TextCols,
  TextCol,
  TextColQuote,
  VideoInner,
  VideoWrapper,
  VideoImgBg,
  GridBgImgOuter,
  GridBgImg,
  ColGroup,
} from './styled';

const HistoryBlock = () => {
  return (
    <Outer>
      <GridBgImgOuter>
        <GridBgImg src={whiteGridImage} />
      </GridBgImgOuter>

      <Heading>
        Historical Significance of <br /> The <b>7th Portal</b>
      </Heading>

      <Video>
        <VideoOuter>
          <VideoInner>
            <VideoImgBg src={imgAll} />
            <VideoWrapper>
              <Player
                videoUrl="https://player.vimeo.com/video/546680618?title=0&byline=0&portrait=0&sidedock=0&controls=0"
                countryOfOrigin=""
                labelText=""
                name=""
                sHeroForm=""
                sPower=""
                title=""
                voiceActor=""
              />
            </VideoWrapper>
          </VideoInner>
        </VideoOuter>
      </Video>

      <ColGroup>

        <TextCols>

        <p>
                    <span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="4" style={{ fontSize: '36px' }}>C</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>reated
                                    The significance of the 7th Portal to the history of digital
                                    animation art on the internet cannot be overstated. </font>
                            </font>
                        </font>
                    </span>
                </p>
                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>More
                                    than <b>twenty years</b> before today’s blockchain-backed digital</font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>{' '}art revolution on the internet</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>,
                                    Stan Lee had a vision for the future of digital comic art: <b>A
                                    multiverse of superheroes and villains</b> that would be born and live
                                    on the then brand-new </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>World
                                    Wide Web</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>.
                                    Lee quickly embraced a vision of how the newly emerging
                                    entertainment medium of the internet would transform superhero
                                    entertainment, and he placed all his focus and creativity to
                                    create the <b>first internet-based superhero</b> character franchises of
                                    his career.</font>
                            </font>
                        </font>
                    </span></p>
                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>Explaining
                                    in 1999 how his career as a creator of comic art led him to the
                                    then brand-new internet medium, Lee stated:</font>
                            </font>
                        </font>
                    </span></p>
                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff"><font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}><i>"I
                                    started in comics when they were in their infancy and we had some
                                    measure of success. Now I have <b>an opportunity to get into the
                                    internet</b> when it is in its infancy and it is a far bigger, more
                                    powerful field than comics ever were.”</i></font>
                            </font>
                        </font>
                    </span></p>
                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>Even
                                    though Lee was in his </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>late
                                    70s at the time</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>,
                                    he was not deterred by the <b>technical complexities</b> of the new
                                    medium. </font>
                            </font>
                        </font>
                    </span>
                </p>
                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>He
                                    explained:</font>
                            </font>
                        </font>
                    </span></p>
                    <i><p ><span style={{ background: '#000000' }}>
                        <font color="#ffffff">“<font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>People
                                    wonder why am I interested in the internet when this seems to be a
                                    young person’s thing? But I think I feel like a young person and
                                    I find that the <b>internet isn’t a matter of age</b>, it’s a matter
                                    of the exciting possibilities that it offers . . . . And I don’t
                                    think it has anything to do with what generation you’re in, it’s
                                    just the excitement of telling stories and feeling that I am
                                    telling them to friends and hoping that these friends will enjoy
                                    them as much as the stories I told years ago. Maybe more. Because
                                    these stories on the internet will be animated, they’ll be 3 &mdash; 
                                    <b> dimensional</b> and they’ll have <b>sound and music and voices.</b> They’ll
                                    be like </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>little
                                    miniature movies</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>.
                                    And I think that if I was <b>200 years old</b> I would be excited about
                                    doing this.”</font>
                            </font>
                        </font>
                    </span></p></i>
        </TextCols>

        <TextCols>

        <p>
                    <span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>To
                                    carry out his vision for this ambitious endeavor, Lee recruited a
                                    stable of over a </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>hundred
                                </font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>of
                                    the</font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    top comic artists </font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>and
                                </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>animators</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    from the best studios in Hollywood to produce the project.
                                    <b>Together</b> they <b>created the first high concept superhero</b> universe
                                    made for the internet and the first new group of superheroes Lee
                                    dreamed up in over <b>30 years</b> &dash; the 7th Portal. Lee envisioned the
                                    Metaverse 20 years before it was a common theme.</font>
                            </font>
                        </font>
                    </span>
                </p>
                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>The
                                    7th Portal was launched at a </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>$1
                                    million </font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>gala
                                    hosted by <b>Dick Clark at Raleigh Studios</b> on February 29, 2000. The
                                    worldwide launch was a testament to the global reach and potential
                                    of the new internet medium, as <b>millions of users</b> tuned in online
                                    from all around the world to witness the birth of the new
                                    franchise. The worldwide debut crashed the servers of Macromedia's
                                    Shockwave and the new endeavor won the <b>Best of Show Web Award in
                                    November 2000</b>, as the best Entertainment Portal on the internet,
                                    beating Warner Bros and Disney’s portals. The launch also
                                    garnered major media attention with </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>feature
                                    stories </font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>in
                                    <b>The New York Times, Time Magazine, The Wall Street Journal</b> and
                                    many others. The media heralded the 7th Portal as pioneering the
                                    use of flash animation to overcome the significant hurdles that
                                    low bandwidth modems presented for internet entertainment at the
                                    time and praised Lee and his team for their efforts to reimagine
                                    comic art for the new medium.</font>
                            </font>
                        </font>
                    </span></p>

                <p><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>Unfortunately,
                                    despite the 7th Portal’s initial </font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>great
                                    success</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>,
                                    the dotcom meltdown caused production to stop on the franchise in
                                    the early 2000’s. The treasure trove of <b>iconic digital art</b> that
                                    was created was <b>locked away</b>. </font>
                            </font>
                        </font>
                    </span>
                </p>
                <p ><span style={{ background: '#000000' }}>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>Nearly
                                    a decade later, a collector acquired Lee’s former company, its{' '}</font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    intellectual property{' '}</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    and the{' '}</font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>creative{' '}</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    assets that were{' '}</font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>produced{' '}</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    with the goal of shining light on the franchise’s historical
                                    significance and the role Lee played as a pioneer in the internet
                                    art revolution. With the advent of{' '}</font>
                            </font>
                        </font>
                        <font color="#3bfdf4">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>blockchain
                                    technology{' '}</font>
                            </font>
                        </font>
                        <font color="#ffffff">
                            <font face="Ubuntu Regular">
                                <font size="2" style={{ fontSize: '20px' }}>
                                    in the digital art space, the collector believes now is the
                                    perfect time to share these digital art treasures and give
                                    collectors a chance to own these unique pieces of internet
                                    history.</font>
                            </font>
                        </font>
                    </span></p>
        </TextCols>
      </ColGroup>
    </Outer>
  );
};

export default HistoryBlock;
