import * as React from "react";
import Layout from "../components/layout";
import WebsiteHead from "../components/WebsiteHead";
import EpisodeCard from "../components/EpisodeCard";
import HeroVideoCard from "../components/HeroVideoCard";
import SmallHeroCards, { SmallHeroCardsHead } from "../components/SmallHeroCards";
import HistoryBlock from "../components/HistoryBlock";
import HistoricPress from "../components/HistoricPress";
import ContactForm from "../components/ContactForm";
import Section from "../components/Section";

import Grid from "../images/background/violet-grid.svg";
import 'react-image-lightbox/style.css';

import {heroes, villains} from "../lib/configs/characters";
import styled from "@emotion/styled";

const GridImg = styled.img`
  width: calc(100% + 400px);
  margin-left: -200px;
  margin-right: -200px;
  max-width: unset;
`;

const IndexPage = () => (
  <Layout>
    <Section resultHeight="unset">
      <WebsiteHead />
    </Section>
    <GridImg src={Grid} alt="" />
    <Section resultHeight="unset" id="section-drop">
      <EpisodeCard />
    </Section>
    <Section resultHeight="unset" id="section-videos">
      <SmallHeroCardsHead
          title="Character Cuts"
          subtitle="Editions"
          text="Seven animated segments of the first webisode introducing each of the heroes of The 7th Portal will be offered to collectors  as editioned NFTs."
        />
      <HeroVideoCard
        cardPosition="right"
        labelText="Limited edition"
        title="Izayus"
        name="Izayus"
        countryOfOrigin="Unknown"
        sHeroForm="N/A"
        sPower="Psychokinesis"
        voiceActor="Stan Lee"
        videoUrl="https://player.vimeo.com/video/546155065?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset">
      <HeroVideoCard
        cardPosition="left"
        labelText="Limited edition"
        title="The Streak"
        name="Rikio Minamoto"
        countryOfOrigin="Japan"
        sHeroForm="The Streak"
        sPower="Super-Speed"
        voiceActor="Jovanni Sy"
        videoUrl="https://player.vimeo.com/video/546155398?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset">
      <HeroVideoCard
        cardPosition="right"
        labelText="Limited edition"
        title="Gossamer"
        name="Greta Brecht"
        countryOfOrigin="Germany"
        sHeroForm="Gossamer"
        sPower="Flight, Intangibility & Invisibility"
        voiceActor="Christina Gordon"
        videoUrl="https://player.vimeo.com/video/546155578?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset">
      <HeroVideoCard
        cardPosition="left"
        labelText="Limited edition"
        title="Oxblood"
        name="Roberto Diaz"
        countryOfOrigin="Brazil"
        sHeroForm="Oxblood"
        sPower="Superhuman, Strength & Durability"
        voiceActor="Carlos Diaz"
        videoUrl="https://player.vimeo.com/video/546155130?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset">
      <HeroVideoCard
        cardPosition="right"
        labelText="Limited edition"
        title="Imitatia"
        name="Anna Nehue"
        countryOfOrigin="India"
        sHeroForm="Imitatia"
        sPower="Shapeshifting"
        voiceActor="Ellora Patniak"
        videoUrl="https://player.vimeo.com/video/546154948?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset">
      <HeroVideoCard
        cardPosition="left"
        labelText="Limited edition"
        title="Conjure Man"
        name="Ozubo Monduma"
        countryOfOrigin="South Africa"
        sHeroForm="Conjure Man"
        sPower="Psychokinesis"
        voiceActor="Joseph Motiki"
        videoUrl="https://player.vimeo.com/video/546155277?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset">
      <HeroVideoCard
        cardPosition="right"
        labelText="Limited edition"
        title="The Thunderer"
        name="Peter Littlecloud"
        countryOfOrigin="USA"
        sHeroForm="Thunderer"
        sPower="Superhuman breath"
        voiceActor="Gordon Michael Woolvett"
        videoUrl="https://player.vimeo.com/video/546155497?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      />
    </Section>
    <Section resultHeight="unset" id="section-cards">
      <SmallHeroCardsHead
        extraEl
        title="Character Cards"
        subtitle="Editions"
        text="“Character Cards” of the original 3D renderings of the 7th Portal’s heroes and villains,
        created in 2000, will be offered as limited edition NFTs to collectors."
      />
      <SmallHeroCards
        characterGroup="Heroes"
        characters={heroes}
      />
    </Section>
    <Section resultHeight="unset">
      <SmallHeroCards
        characterGroup="Villains"
        characters={villains}
      />
    </Section>
    <Section resultHeight="unset" id="section-history">
      <HistoryBlock />
    </Section>
    <Section resultHeight="unset">
      <HistoricPress />
    </Section>
    <Section resultHeight="unset" id="section-contact">
      <ContactForm />
    </Section>
  </Layout>
);

export default IndexPage;
