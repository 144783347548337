import {css} from '@emotion/react';
import styled from '@emotion/styled';

import {below} from '../../../styles/utils/media';

export const LabelWrapper = styled.div`
  width: 305px;
  height: 78px;
  background: rgba(255, 226, 69, 1);
  color: #000000;
  font-family: BankGothic-Medium, sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 80px;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    display: block;
    border-top: 50px solid rgba(139, 117, 0, 1);

    ${below.m2} {
      border-top: 40px solid rgba(139, 117, 0, 1);
    }

    ${below.m1} {
      display: none;
    }

    ${({position}) => {
      switch (position) {
        case 'left':
          return css`
            left: 2px;
            border-left: 48px solid transparent;

            ${below.m2} {
              border-left: 38px solid transparent;
            }
        `;
        case 'right':
        default:
          return css`
            right: 2px;
            border-right: 48px solid transparent;

            ${below.m2} {
              border-right: 38px solid transparent;
            }
        `;
      }
    }}
  }
  
  ${below.m3} {
    width: 280px;
    height: 60px;
    font-size: 28px;
    line-height: 64px;
  }
  
  ${below.m2} {
    width: 250px;
    height: 50px;
    font-size: 24px;
    line-height: 54px;
  }
  
  ${below.m1} {
    width: 155px;
    height: 35px;
    font-size: 14px;
    line-height: 38px;
  }
`;
