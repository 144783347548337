import { css } from '@emotion/react';

export default css`
  font-family: Ubuntu Regular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
`;
