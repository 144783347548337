import { css } from '@emotion/react';

export default css`
  font-family: Ubuntu Regular, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
`;
